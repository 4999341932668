import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faGithubSquare,
  faInstagramSquare,
  faLinkedin,
  IconDefinition
} from '@fortawesome/free-brands-svg-icons'
// import {
//   faFileDownload
// } from '@fortawesome/free-solid-svg-icons'

import snowflakeLogo from './img/snowflake_logo.svg'
import fireboltLogo from './img/firebolt_logo.png'
import { ReactComponent as SolLogo } from './img/sol_logo.svg'
import sapLogo from './img/sap_logo.svg'
import { ReactComponent as IbmLogo } from './img/ibm_logo.svg'
import { ReactComponent as CcLogo } from './img/cc_logo.svg'
import ethzLogo from './img/ethz_logo.svg'
import uomLogo from './img/uom_logo.png'

import './App.sass';

interface LinkIconProps { href: string; icon: IconDefinition; }
const LinkIcon: React.FC<LinkIconProps> = ({ href, icon }) => <a href={href} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={icon} /></a>

interface LinkProps { url: string; }
const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({ url, children }) => <a href={url} target="_blank" rel="noreferrer">{children}</a>

const PgpFingerprint: React.FC = () => <div className="PgpFingerprint">
    <span>PGP fingerprint</span>
    <pre>38E97B83679586294009A14F4E706B783E18124F</pre>
    {/* <a href="https://keys.openpgp.org/vks/v1/by-fingerprint/38E97B83679586294009A14F4E706B783E18124F"><FontAwesomeIcon icon={faFileDownload} /></a> */}
</div>

const Header: React.FC = () => <div className="Header">
  <header className="bar">
    <LinkIcon href="https://github.com/skurella" icon={faGithubSquare} />
    <LinkIcon href="https://www.linkedin.com/in/sebastian-kurella/" icon={faLinkedin} />
    <LinkIcon href="https://www.instagram.com/kurellasebastian/" icon={faInstagramSquare} />
    <LinkIcon href="https://www.facebook.com/sebastian.kurella/" icon={faFacebookSquare} />
  </header>
  <PgpFingerprint />
  <div className="companies">
    <Link url="https://www.snowflake.com/"><img src={snowflakeLogo} alt="Snowflake logo" /></Link>
    <Link url="https://firebolt.io/"><img src={fireboltLogo} alt="Firebolt logo" /></Link>
    <Link url="https://soltag.info/"><SolLogo title="Sol logo" /></Link>
    <Link url="https://www.sap.com/germany/products/hana.html"><img src={sapLogo} alt="SAP logo" /></Link>
    <Link url="https://www.zurich.ibm.com/snapml/"><IbmLogo title="IBM logo" /></Link>
    <Link url="https://www.cambridgeconsultants.com/markets/telecoms-mobile"><CcLogo title="Cambridge Consultants logo" /></Link>
    <Link url="https://ee.ethz.ch/"><img src={ethzLogo} alt="ETH Zurich logo" /></Link>
    <Link url="https://www.eee.manchester.ac.uk/"><img src={uomLogo} alt="University of Manchester logo" /></Link>
  </div>
</div>

const App: React.FC = () => <div className="App">
  <Header />
</div>

export default App;
